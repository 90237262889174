import { useMemo, useRef } from 'react';

import PageContainer from '../../../layout/PageContainer';
import PageHeader from '../../../layout/PageHeader';
import FormGroup from '../../../components/FormGroup';
import Icon from '../../../components/Icon';
import ReportTable from '../components/ReportTable';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { ProgressBar } from 'primereact/progressbar';
import { Toolbar } from 'primereact/toolbar';
import { ToggleButton } from 'primereact/togglebutton';

import { ReportColumn } from '../../../types/reports';
import { Organisation } from '../../../types/organisation';
import { RookieProduct } from '../../../types/licences';
import { config } from '../reportConfig';

interface Props {
    category: string;
    data: any;
    dateRange: string;
    hideEmptyRows: boolean;
    loading?: boolean;
    loadingProgress?: number;
    options: any;
    organisation?: Organisation;
    selectedSeasons: Record<string, string>;
    onExport: (dataTable: DataTable<any>) => void;
    onSeasonChange: (teamID: string, seasonID: string) => void;
    onSeasonDropdownOpen: (teamID: string) => void;
    setCategory: (value: any) => void;
    setDateRange: (value: any) => void;
    setHideEmptyRows: (show: boolean) => void;
}

const OrgTeamFairPlayReportView = (props: Props) => {
    const {
        category,
        data,
        dateRange,
        hideEmptyRows,
        loading,
        loadingProgress,
        options,
        organisation,
        selectedSeasons,
        setCategory,
        onExport,
        onSeasonChange,
        onSeasonDropdownOpen,
        setDateRange,
        setHideEmptyRows,
    } = props;

    const dataTable = useRef<DataTable<any>>(null);

    const leftToolbar = (
        <div className="p-button-group">
            <FormGroup label="Category">
                <Dropdown
                    value={category}
                    onChange={(e) => setCategory(e.value)}
                    options={options.categories}
                />
            </FormGroup>
            <FormGroup label="Date Range">
                <Dropdown
                    value={dateRange}
                    onChange={(e) => setDateRange(e.value)}
                    options={options.dateRanges}
                />
            </FormGroup>
        </div>
    );

    const rightToolbar = (
        <div className="p-button-group">
            <ToggleButton
                onLabel="Hide Empty Rows"
                offLabel="Hide Empty Rows"
                iconPos="right"
                onIcon={
                    <span className="p-button-icon p-button-icon-right">
                        <Icon name="check_box" fill />
                    </span>
                }
                offIcon={
                    <span className="p-button-icon p-button-icon-right">
                        <Icon name="check_box_outline_blank" />
                    </span>
                }
                checked={hideEmptyRows}
                onChange={(e) => setHideEmptyRows(e.value)}
                pt={{
                    box: {
                        className: 'p-button-sm',
                    },
                }}
            />
        </div>
    );

    const renderPlayerList = (playerList: string[]) => {
        return (
            playerList &&
            playerList.map((player, i) => (
                <div key={`player-${i}`}>{player}</div>
            ))
        );
    };

    const visibleColumns = useMemo(() => {
        const seasonCol: ReportColumn = {
            id: 'season',
            name: 'Season',
            shortName: 'Season',
            field: 'team.teamName',
            sortable: false,
            body: (row: any) => {
                if (!row || !row.team) return null;

                const selected = selectedSeasons[row.team.teamID];

                return (
                    <Dropdown
                        style={{ width: '100%' }}
                        value={selected}
                        onChange={(e) =>
                            onSeasonChange(row.team.teamID, e.value)
                        }
                        onShow={() =>
                            onSeasonDropdownOpen &&
                            onSeasonDropdownOpen(row.team.teamID)
                        }
                        options={options.seasons[row.team.teamID]}
                        className={row?.error ? 'p-invalid' : ''}
                        disabled={row?.reportState?.isLoading}
                        valueTemplate={(val) => {
                            return (
                                <div className="p-dropdown-value">
                                    {val && val.label}

                                    {row?.error && <Icon name="error" />}
                                </div>
                            );
                        }}
                        tooltip={
                            row?.reportState?.isError &&
                            'Unable to find report.'
                        }
                    />
                );
            },
        };

        let columns = [config.team, seasonCol, config.event];

        switch (category) {
            case 'Minimum Game Time':
                columns.push({
                    id: 'pBelowTarget',
                    field: 'playerLists.pBelowTarget',
                    header: 'Below Minimum Game Time',
                    shortName: 'pBelowTarget',
                    name: 'pBelowTarget',
                    align: 'left',
                    alignHeader: 'left',
                    body: (row) =>
                        renderPlayerList(row.playerLists.pBelowTarget),
                });
                break;
            case 'Disciplinary':
                columns.push({
                    id: 'disc',
                    field: 'playerLists.disciplineList',
                    header: 'Disciplinary',
                    shortName: 'disc',
                    name: 'disc',
                    align: 'left',
                    alignHeader: 'left',
                    body: (row) =>
                        renderPlayerList(row.playerLists.disciplineList),
                });
                break;
            case 'Injury':
                columns.push({
                    id: 'inj',
                    field: 'playerLists.injuryList',
                    header: 'Injury',
                    shortName: 'inj',
                    name: 'inj',
                    align: 'left',
                    alignHeader: 'left',
                    body: (row) => renderPlayerList(row.playerLists.injuryList),
                });
                break;
            case 'Assessment':
                columns.push({
                    id: 'assess',
                    field: 'playerLists.assessmentList',
                    header: 'Assessment',
                    shortName: 'Assess',
                    name: 'assess',
                    align: 'left',
                    alignHeader: 'left',
                    body: (row) =>
                        renderPlayerList(row.playerLists.assessmentList),
                });
                break;
            case 'Opposition':
                columns.push({
                    id: 'oppo',
                    field: 'playerLists.oppositionList',
                    header: 'Opposition',
                    shortName: 'oppo',
                    name: 'oppo',
                    align: 'left',
                    alignHeader: 'left',
                    body: (row) =>
                        renderPlayerList(row.playerLists.oppositionList),
                });
        }

        return columns;
    }, [
        onSeasonChange,
        selectedSeasons,
        options,
        category,
        onSeasonDropdownOpen,
    ]);

    return (
        <PageContainer>
            <PageHeader
                title="Summary Report"
                actions={[
                    {
                        onClick: () =>
                            dataTable.current && onExport(dataTable.current),
                        label: 'Export',
                        severity: 'secondary',
                        icon: 'download',
                    },
                ]}
            />
            <Toolbar
                className="report-toolbar"
                start={leftToolbar}
                end={rightToolbar}
            />

            {loading ? (
                <ProgressBar
                    value={loadingProgress}
                    style={{ height: '20px' }}
                />
            ) : (
                <ReportTable
                    ref={dataTable}
                    data={data}
                    columns={visibleColumns}
                    product={RookieProduct.GameDay}
                    dataTableProps={{
                        sortField: 'team.teamName',
                        sortOrder: 1,
                        exportFilename: `${organisation?.organisationName} - Fair Play Report`,
                        rowGroupMode: 'rowspan',
                        groupRowsBy: 'team.teamName',
                    }}
                />
            )}
        </PageContainer>
    );
};

export default OrgTeamFairPlayReportView;
