import {
    Channel,
    ChannelHeader,
    ChannelList,
    Chat,
    LoadingIndicator,
    MessageInput,
    MessageList,
    Thread,
    useCreateChatClient,
    Window,
} from 'stream-chat-react';

import 'stream-chat-react/dist/css/v2/index.css';
import PageHeader from '../../layout/PageHeader';
import { Dialog } from 'primereact/dialog';
import MessageSettingsForm from './MessageSettingsForm';
import { BaseEntityType } from '../../types/common';
import { MessageSettings } from '../../types/messages';

interface Props {
    userData: any;
    streamToken: any;
    filters: any;
    sort: any;
    options: any;
    showSettings: boolean;
    setShowSettings: (show: boolean) => void;
    entityID: string;
    entityType: BaseEntityType;
    messageSettings?: MessageSettings;
}

const apiKey = process.env.REACT_APP_GET_STREAM_API_KEY || '';

const MessagesView = (props: Props) => {
    let chatClient = useCreateChatClient({
        apiKey,
        tokenOrProvider: props.streamToken,
        userData: props.userData,
    });

    if (!chatClient) {
        return <LoadingIndicator />;
    }

    return (
        <div className="chat-wrapper">
            <Chat client={chatClient} theme="str-chat__theme-light">
                <div className="chat-sidebar">
                    <PageHeader
                        title="Messages"
                        showBreadcrumbs={false}
                        actions={[
                            {
                                icon: 'settings',
                                onClick: () => props.setShowSettings(true),
                                severity: 'secondary',
                            },
                        ]}
                    />
                    <ChannelList
                        filters={props.filters}
                        sort={props.sort}
                        options={props.options}
                    />
                </div>
                <div className="chat-details">
                    <Channel>
                        <Window>
                            <ChannelHeader />
                            <MessageList />
                            <MessageInput />
                        </Window>
                        <Thread />
                    </Channel>
                </div>
            </Chat>
            <Dialog
                visible={props.showSettings}
                onHide={() => props.setShowSettings(false)}
                header="Message Settings"
            >
                <MessageSettingsForm
                    entityID={props.entityID}
                    entityType={props.entityType}
                    messageSettings={props.messageSettings}
                    onSuccess={() => props.setShowSettings(false)}
                />
            </Dialog>
        </div>
    );
};

export default MessagesView;
