import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { result } from 'lodash';

import { Avatar } from 'primereact/avatar';
import { ColumnBodyOptions } from 'primereact/column';
import { Skeleton } from 'primereact/skeleton';
import { Tag } from 'primereact/tag';
import { Tooltip } from 'primereact/tooltip';

import Icon from '../../../components/Icon';
import EntityAvatar from '../../../components/EntityAvatar';
import RookieButton from '../../../components/RookieButton';

import {
    calculateIntegrityScore,
    getBenchRating,
    getEnvironmentComposition,
    getMatchedIntegrityFlags,
    getRatingFromPercentage,
    MAX_INT_SCORE,
    roundToDecimalPlaces,
} from '../helpers';

import { niceFlags } from '../../reports/constants';
import { config } from '../reportConfig';
import {
    formatPercentage,
    formatTime,
    getFirstChars,
} from '../../../util/helper';

import { BaseEntityType } from '../../../types/common';

import {
    GameStat,
    GameSummary,
    ReportColumnOptions,
    ReportTimeDisplays,
} from '../../../types/reports';

export const playerNumberCell = (row: GameStat): React.ReactNode => {
    if (!row || !row.player) {
        return <Skeleton width="2rem" height="1rem" />;
    }

    if (row.player.uniformNumber) {
        return <div>{row.player.uniformNumber}</div>;
    }
};

export const playerNameCell = (
    row: GameStat,
    options: any
): React.ReactNode => {
    if (!row || !row.player) {
        return <Skeleton width="2rem" height="1rem" />;
    }

    let fullName = '';

    if (row.player.playerID) {
        fullName = `${row.player.firstName} ${row.player.lastName}`;
    } else {
        fullName =
            row.playerID === 'team'
                ? 'Team (Unassigned)'
                : row.playerID === 'opposition'
                ? 'Opposition'
                : `Unknown Player (${row.playerID})`;
    }

    return (
        <div className="detail-cell">
            <Avatar
                label={
                    row.player.playerID
                        ? getFirstChars(fullName, 2).toUpperCase()
                        : '?'
                }
                image={row.player && row.player.playerImageURL}
                shape="circle"
            />
            <span>{fullName}</span>

            {row.timeInjury > 0 && !options.props.hideInjuryTooltip && (
                <RookieButton
                    style={{
                        width: '24px',
                        height: '24px',
                        color: '#999',
                    }}
                    tooltip={`${formatTime(
                        row.timeInjury
                    )} time spent in injury.`}
                    tooltipOptions={{
                        showDelay: 500,
                        style: { maxWidth: '200px' },
                    }}
                    severity="secondary"
                    text={true}
                    icon="personal_injury"
                />
            )}
        </div>
    );
};

export const playerGroupCell = (row: GameStat): React.ReactNode => {
    if (!row || !row.player) {
        return <Skeleton width="2rem" height="1rem" />;
    }

    return (
        <Avatar
            style={{
                background: row.player ? row.player.colour : 'transparent',
                width: '24px',
                height: '16px',
                border: '1px solid #e5e5e5',
            }}
        />
    );
};

export const timeCell = (
    row: GameStat,
    options: ReportColumnOptions
): React.ReactNode => {
    if (!row) {
        return <Skeleton width="2rem" height="1rem" />;
    }

    const statID = options.field;
    const val = row[statID];
    const totalTime = row.PT;

    if (statID.includes('count')) {
        return val;
    }

    if (statID === 'PGT') {
        return isNaN(val) ? '-' : `${Math.round(val * 100)}%`;
    }

    return Math.round((val / totalTime) * 100) + '%';
};

export const textCell = (
    row: GameStat,
    options: ReportColumnOptions
): React.ReactNode => {
    if (!row) {
        return <Skeleton width="2rem" height="1rem" />;
    }

    const value: string = result(row, options.field);

    return value ? value : '-';
};

export const percentageCell = (
    row: GameStat,
    options: ReportColumnOptions
): React.ReactNode => {
    if (!row) {
        return <Skeleton width="2rem" height="1rem" />;
    }

    return Math.round(Number(result(row, options.field)) * 100) + '%';
};

export const playerAlertTargetCell = (
    pgt: number,
    target: number
): React.ReactNode => {
    if (!pgt && pgt !== 0) {
        return <Skeleton width="2rem" height="1rem" />;
    }

    const checked = pgt >= target;
    return (
        <Icon
            fill
            style={{
                color: checked ? '#11b411' : '#fb4747',
            }}
            title={'PGT ' + Math.round(pgt * 100) + '%'}
            name={checked ? 'check_circle' : 'cancel'}
        />
    );
};

export const teamHeader = (teamName: string, teamID?: string) => {
    return (
        <div className="detail-cell">
            <EntityAvatar
                entityType={BaseEntityType.teams}
                entityID={teamID || ''}
                entityName={teamName}
            />
            <span>{teamName}</span>
        </div>
    );
};

export const timeZoneCell = (row: GameStat, options: ReportColumnOptions) => {
    const key = options.field;
    const timeDisplay = options?.views?.timeDisplay;

    const zoneTime = row[key];
    return timeDisplay === ReportTimeDisplays.Percentage
        ? formatPercentage(zoneTime / row.timeOnField)
        : formatTime(zoneTime);
};

export const matchResultCell = (row: GameStat) => {
    if (row.wins > 0 || row.losses > 0 || row.draws || 0) {
        return (
            <Icon
                name={
                    row.wins > 0
                        ? 'check_circle'
                        : row.losses > 0
                        ? 'cancel'
                        : 'indeterminate_check_box'
                }
                fill
                style={{
                    color:
                        row.wins > 0
                            ? 'green'
                            : row.losses > 0
                            ? 'red'
                            : '#808080',
                }}
            />
        );
    } else {
        return null;
    }
};

export const renderBenchRating = (percentage: number, totalAvg: number) => {
    if (percentage === undefined || percentage === null || isNaN(percentage)) {
        return (
            <Tag
                style={{
                    width: '40px',
                    color: '#fff',
                    background: '#808080',
                }}
            >
                NA
            </Tag>
        );
    }

    const rating = getBenchRating(percentage, totalAvg);

    return (
        <Tag
            style={{
                width: '40px',
                color: '#fff',
                background: rating.color,
            }}
        >
            {formatPercentage(percentage / 100)}
        </Tag>
    );
};

export const renderRating = (percentage: number) => {
    if (percentage === undefined || percentage === null || isNaN(percentage)) {
        return (
            <Tag
                style={{
                    width: '40px',
                    color: '#fff',
                    background: '#808080',
                }}
            >
                NA
            </Tag>
        );
    }

    const rating = getRatingFromPercentage(percentage);

    return (
        <Tag
            style={{
                width: '40px',
                color: '#fff',
                background: rating.color,
            }}
        >
            {Math.round(percentage * 100)}
        </Tag>
    );
};

export const integrityScoreCell = (
    row: GameSummary,
    options: ColumnBodyOptions
) => {
    if (!row) return;

    const { hsArticle } = config[options.field];
    const intScore = calculateIntegrityScore(row);
    const matchedFlags = !row.flags ? [] : getMatchedIntegrityFlags(row.flags);
    const rating = getRatingFromPercentage(intScore / MAX_INT_SCORE);

    return (
        <div className={`integrity-score-icon-${row.eventID}`}>
            <Tooltip
                target={`.integrity-score-icon-${row.eventID}`}
                autoHide={false}
            >
                <div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        Integrity Score: {intScore}/{MAX_INT_SCORE}
                        <RookieButton
                            style={{
                                backgroundColor: 'transparent',
                                border: 'none',
                                color: '#ff6700',
                                opacity: '0.9',
                            }}
                            text={true}
                            data-beacon-article={hsArticle}
                            icon="help"
                        />
                    </div>
                    {Array.isArray(matchedFlags) && (
                        <div>
                            {intScore === MAX_INT_SCORE ? (
                                <div>Issues: Nil.</div>
                            ) : (
                                <div>
                                    Issues:
                                    {matchedFlags.map((flag, index) => (
                                        <div key={`flag-${index}`}>
                                            {niceFlags[
                                                flag as keyof typeof niceFlags
                                            ] || flag}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </Tooltip>
            <Icon
                name={rating.icon}
                size="large"
                style={{ color: rating.color }}
            />
        </div>
    );
};

export const eventCell = (row: GameSummary, options: ColumnBodyOptions) => {
    if (!row || !row.event) {
        return <Skeleton />;
    }

    return (
        <Link to={''} style={{ color: '#000', textDecoration: 'none' }}>
            <div
                style={{
                    fontWeight: '600',
                    marginBottom: '5px',
                }}
            >
                {row.event.eventName}
            </div>
            <div
                style={{
                    fontSize: '12px',
                    opacity: '0.7',
                    whiteSpace: 'nowrap',
                }}
            >
                {row.event.startDateTime &&
                    format(
                        new Date(row.event.startDateTime),
                        'eeee, MMMM do, yyyy'
                    )}
            </div>
        </Link>
    );
};

export const teamCell = (row: GameSummary, options: ColumnBodyOptions) => {
    if (!row || !row.team) return null;

    return (
        <div className="detail-cell">
            <EntityAvatar
                entityType={BaseEntityType.teams}
                entityID={row.team.teamID || ''}
                entityName={row.team.teamName}
            />
            <span>{row.team.teamName}</span>
        </div>
    );
};

export const envCell = (row: GameSummary, options: ColumnBodyOptions) => {
    if (!row || !row.integrityScore) return null;
    const data = getEnvironmentComposition(row);

    return (
        <div>
            <p>{row.oppositionList}</p>
            <ul>
                <li>intergrity: {data.scores.integrity}</li>
                <li>discipline: {data.scores.discipline}</li>
                <li>minGameTime: {data.scores.minGameTime}</li>
                <li>FairGameTime{data.scores.fairGameTime}</li>
            </ul>
        </div>
    );
};

export const timeStintCell = (data: any, options: ColumnBodyOptions) => {
    const { field } = options;
    const stintKey = field.replace('time', 'countStint');
    const stint = data[stintKey];

    return stint
        ? `${data[field]} (${roundToDecimalPlaces(stint, 1)})`
        : data[field];
};
