import { Image } from 'primereact/image';
import { Tag, TagProps } from 'primereact/tag';
import { productDetails } from '../util/constants';

interface Props extends TagProps {
    product?: string;
}

const ProductTag = ({ product, title, icon }: Props) => {
    const productDetail = product && productDetails[product];

    if (product && productDetail) {
        return (
            <Tag
                className="product-tag"
                icon={
                    <Image
                        src={productDetail.logomark}
                        height="24px"
                        alt={productDetail.title}
                    />
                }
                value={productDetail.title}
            />
        );
    }

    return <Tag className="product-tag" icon={icon} value={title} />;
};

export default ProductTag;
