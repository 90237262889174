import type { ChannelFilters, ChannelOptions, ChannelSort } from 'stream-chat';
import {
    useGetMessageSettingsQuery,
    useGetMessageTokenQuery,
} from '../../api/messages';

import MessagesView from './MessagesView';
import { useEffect, useMemo, useState } from 'react';
import { UserDetails } from '../../types/user';
import { useNavigate, useParams } from 'react-router-dom';
import { BaseEntityType } from '../../types/common';
import Loader from '../../components/Loader';

interface Props {
    user: UserDetails;
}

const MessagesContainer = (props: Props) => {
    const { user } = props;
    const { userID } = user;

    const params = useParams();

    const [showSettings, setShowSettings] = useState(false);

    let filters: ChannelFilters = {
        members: { $in: [userID] },
    };

    let entityType = BaseEntityType.users;
    let entityID = '';

    // Store entityType and entityID
    if (params.teamID) {
        entityType = BaseEntityType.teams;
        entityID = params.teamID;
    } else if (params.organisationID) {
        entityType = BaseEntityType.organisations;
        entityID = params.organisationID;
    } else if (params.associationID) {
        entityType = BaseEntityType.associations;
        entityID = params.associationID;
    }

    // Conditionally filter channels to this entity
    if (entityID) {
        filters = {
            ...filters,
            type: { $eq: 'team' },
            team: [entityType, entityID].join('_'),
        };
    }

    const { data: messageSettingsResponse, isLoading: messageSettingsLoading } =
        useGetMessageSettingsQuery(
            { entityID, entityType },
            {
                skip: !entityID,
            }
        );

    const messageSettings = messageSettingsResponse?.data || null;

    const navigate = useNavigate();

    useEffect(() => {
        // Handle redirection to message setup if message settings doesnt exist
        if (entityID && !messageSettingsLoading && !messageSettings) {
            navigate('setup', {
                replace: true,
            });
        }
    }, [navigate, entityID, messageSettingsLoading, messageSettings]);

    const { data: messageTokenResponse } = useGetMessageTokenQuery(
        { userID: userID || '' },
        {
            skip: !userID,
        }
    );

    const streamToken = messageTokenResponse?.data?.token || null;

    const userData = useMemo(
        () => ({
            id: userID,
            name: user?.name,
            image: user?.picture,
        }),
        [user, userID]
    );

    const sort: ChannelSort = { last_message_at: -1 };

    const options: ChannelOptions = {
        limit: 10,
    };

    if (!streamToken) {
        return <Loader />;
    }

    return (
        <MessagesView
            streamToken={streamToken}
            userData={userData}
            filters={filters}
            sort={sort}
            options={options}
            showSettings={showSettings}
            setShowSettings={setShowSettings}
            entityType={entityType}
            entityID={entityID}
            messageSettings={messageSettings || undefined}
        />
    );
};

export default MessagesContainer;
