import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { useGetMessageSettingsQuery } from '../../api/messages';

import MessageSetupView from './MessageSetupView';

import { BaseEntityType } from '../../types/common';

const MessageSetupContainer = () => {
    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    let entityType = BaseEntityType.users;
    let entityID = '';

    // Store entityType and entityID
    if (params.teamID) {
        entityType = BaseEntityType.teams;
        entityID = params.teamID;
    } else if (params.organisationID) {
        entityType = BaseEntityType.organisations;
        entityID = params.organisationID;
    } else if (params.associationID) {
        entityType = BaseEntityType.associations;
        entityID = params.associationID;
    }

    const { data: messageSettingsResponse, isLoading } =
        useGetMessageSettingsQuery(
            { entityID, entityType },
            {
                skip: !entityID,
            }
        );

    const messageSettings = messageSettingsResponse?.data || undefined;

    const handleSuccess = () => {
        navigate(location.pathname.replace('setup', '')); // refresh
    };

    return (
        <MessageSetupView
            entityType={entityType}
            entityID={entityID}
            onSuccess={handleSuccess}
            isLoading={isLoading}
            messageSettings={messageSettings}
        />
    );
};

export default MessageSetupContainer;
