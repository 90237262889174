import { Dialog } from 'primereact/dialog';

import PageHeader from '../../layout/PageHeader';
import PageContainer from '../../layout/PageContainer';

import PostForm from './PostForm';
import PostItem from './PostItem';
import RookieButton from '../../components/RookieButton';

import { Post } from '../../types/posts';
import { Roles } from '../../types/roles';
import { Route } from '../../types/route';
import { BaseEntityType } from '../../types/common';
import Loader from '../../components/Loader';

interface Props {
    entityType?: BaseEntityType;
    entityID?: string;
    data: Post[];
    isError: boolean;
    isFetching: boolean;
    isLoading: boolean;
    onLoadMore?: () => void;
    onDelete?: (postID: string) => void;
    onEdit?: (post: Post) => void;
    roles: Roles;
    showPagination: boolean;
    onCloseCreateModal: () => void;
    onOpenCreateModal: () => void;
    showComposerModal: boolean;
    editing: Post | null;
    route: Route;
    permissions: {
        canCreate: boolean;
        canDelete: boolean;
        canEdit: boolean;
        canView: boolean;
    };
}

const PostsView = (props: Props) => {
    return (
        <PageContainer size="narrow">
            <PageHeader
                title="Posts"
                actions={
                    props.permissions.canCreate
                        ? [
                              {
                                  label: 'Create Post',
                                  onClick: props.onOpenCreateModal,
                              },
                          ]
                        : []
                }
            />
            {props.isLoading ? (
                <Loader />
            ) : (
                <div className="posts-listing">
                    {props.data.map((post) => (
                        <PostItem
                            key={post.postID}
                            post={post}
                            entityType={props.entityType}
                            entityID={props.entityID}
                            onEdit={props.onEdit}
                            onDelete={props.onDelete}
                        />
                    ))}
                    {props.showPagination && props.onLoadMore && (
                        <RookieButton
                            label="Load More"
                            onClick={props.onLoadMore}
                            size="large"
                            className="load-more"
                            severity="secondary"
                            loading={props.isFetching}
                        />
                    )}
                </div>
            )}
            {props.entityType && props.entityID && (
                <Dialog
                    onHide={props.onCloseCreateModal}
                    visible={props.showComposerModal}
                    header={props.editing ? 'Edit Post' : 'Create post'}
                    resizable={false}
                    draggable={false}
                >
                    <PostForm
                        post={props.editing || undefined}
                        entityType={props.entityType}
                        entityID={props.entityID}
                        onUpdate={() => props.onCloseCreateModal()}
                        onCreate={() => props.onCloseCreateModal()}
                        onError={() => props.onCloseCreateModal()}
                    />
                </Dialog>
            )}
        </PageContainer>
    );
};

export default PostsView;
